/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

markdown, .card-content-md, .card-content-md markdown{
  h1, h2, h3, h4 {
    margin-bottom: .5rem;
    font-weight: 900;
    color: var(--ion-color-dark-shade)
  }
  h1 {
    margin-top: 1rem;
    font-size: 2.5rem;
    opacity: 0.6;
  }

  .lead{
    font-size: 1.1rem;
    font-weight: 600;
  }

  h2 {
    margin-top: 3rem;
    font-size: 2rem;
    opacity: 0.8;
  }

  h3 {
    margin-top: 1rem;
    margin-bottom: .25rem;
    font-size: 1.3rem;
  }

  h4 {
    margin-top: .5rem;
    margin-bottom: .25rem;
    font-size: 1.2rem;
  }

  p{
    color: var(--ion-color-dark);
    font-size: 1rem;
    margin-bottom: .75rem;
  }

  ul, ol{

  }

  blockquote{
    border-left: var(--ion-color-success) 1rem solid;
    background: var(--ion-color-light);
    margin: 1rem 0;
    padding: 1rem;
    p{
      margin: 0 0 1rem 0;
      &:last-of-type{
        margin: 0;
      }
    }
    ol, ul{
      margin: 0;
      padding: 0 1rem;
      line-height: 2;
    }
  }

  pre{
    background: var(--ion-color-light);
    color: var(--ion-color-secondary);
    margin: 1rem 0;
    padding: 1rem;
  }

  a{

  }

  code{
    color: var(--ion-color-secondary);
  }

  table{
    background: var(--ion-color-light);
    margin-bottom: 1rem;
    tr{
      &:nth-child(even) {
        background: var(--ion-color-light-shade);
      }
    }
    th, td{
      padding: .5rem 1rem;
      text-align: left;
    }
    a{
      text-decoration: none;
    }
  }
}

